<template>
  <div className="html_content" v-html="bannerInfo.content"/>
</template>

<script>
import { videoJobTutorial } from '../api/collarVideo'

export default {
  name: "collarVideo",
  data() {
    return {
      bannerInfo: {}
    }
  },
  methods: {
    initData(bannerId) {
      videoJobTutorial({id: bannerId}).then((res) => {
        document.title = "如何领取视频"
        this.bannerInfo = res.data
      });
    },
  },
  mounted() {
    let query = this.$route.query;
    console.log(JSON.stringify(query))
    let SKLM_WX_USER_TOKEN = this.$route.query.sklmWxUserToken
    localStorage.setItem("SKLM_WX_USER_TOKEN", SKLM_WX_USER_TOKEN)
    let bannerId = this.$route.query.bannerId
    this.initData(bannerId);
  },
}
</script>

<style scoped>
.html_content {
  padding: 2%;
}
</style>
